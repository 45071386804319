$(function () {
  // scroll spisu treści w artykułach
  if ($("[data-dynamic-list]").length > 0) {
    $("[data-dynamic-list] a").click(function (e) {
      e.preventDefault();
      let itemId = $(this).attr("href");
      $("html, body").animate(
        {
          scrollTop: $(itemId).offset().top - 80,
        },
        300
      );
    });
  }

  // dynamiczne style podpisu zdjec w artykule z acms
  if ($(".article__entry p a img").length > 0) {
    $(".article__entry p a img").each(function () {
      $(this)
        .parent()
        .parent()
        .addClass("articleImgCaption withoutTags")
        .find("em")
        .addClass("articleImgCaption inside")
        .end()
        .next("p")
        .find("em")
        .addClass("articleImgCaption");
    });
  }

  // Usuwanie białych spacji w artykułach
  if ($(".article__entry p").length > 0) {
    $(".article__entry p")
      .filter(function () {
        return this.innerHTML.replace("&nbsp;", " ").trim() == "";
      })
      .remove();
  }

  // Pokazywanie i ukrywanie głównej treści artykułu
  if ($("[data-text-read-more-area]").length > 0) {
    const textArea = $("[data-text-read-more-area]");
    let textAreaHeight = textArea.height();
    textArea.css({
      height: "200px",
      overflow: "hidden",
    });

    $("[data-text-read-more-button]").click(function () {
      const button = $(this).parent();
      button.toggleClass("expanded");

      if (button.hasClass("expanded")) {
        textArea.animate(
          {
            height: textAreaHeight,
            overflow: "visible",
          },
          500,
          function () {
            textArea.css({
              height: "auto",
            });
          }
        );
      } else {
        textArea.animate({
          height: "200px",
          minHeight: "200px",
          overflow: "hidden",
        });
        $("html, body").animate(
          {
            scrollTop: textArea.offset().top - 80,
          },
          300
        );
      }

      var buttonText = button.hasClass("expanded") ? "Ukryj" : "Czytaj więcej";
      $(this).children().text(buttonText);
    });
  }

  // Social
  if ($("[data-box-share]").length > 0) {
    $("[data-box-share]").jsSocials({
      showCount: false,
      showLabel: true,
      shares: [
        { share: "facebook", label: "Udostępnij", logo: "icon-fb" },
        { share: "twitter", label: "Udostepnij", logo: "icon-x-twitter" },
        { share: "linkedin", label: "Udostepnij", logo: "icon-linkedin" },
      ],
    });

    //ukrywamy sociale jak nie ma leada
    if ($("[data-lead]").length === 0) {
      $("[data-box-share]").hide();
    }
  }

  // Fancybox dla zdjęć które nie są zdjeciami z bb code gallery
  if ($(".article__entry a:not(.bbGallery__lnk)").length > 0) {
    $(".article__entry a:not(.bbGallery__lnk)").each(function () {
      if (
        $(this).hasClass("lnkFancybox") ||
        $(this).attr("data-fancybox-group")
      ) {
        $(this)
          .removeAttr("data-fancybox-group")
          .removeClass("lnkFancybox")
          .attr("data-fancybox", "galeria_artykulowa");
      }
    });
  }

  // Fancybox dla zdjęć z bb code gallery
  if ($("[data-bbgallery-box]").length > 0) {
    const nbOfGalleriesInArticle = $("[data-bbgallery-box]").length;
    //indywidualizacja kontenerów każdej galerii
    $("[data-bbgallery-box]").each(function (i) {
      $(this).attr("id", `bbgallery${i}`);
    });
    for (let i = 0; i < nbOfGalleriesInArticle; i++) {
      let galleryGroupIndex = i;
      $(`#bbgallery${galleryGroupIndex} .lnkFancybox`).each(function () {
        $(this).attr("data-fancybox", `galeria_artykulowa${galleryGroupIndex}`);
      });
    }
  }

  // Table
  if ($(".article__entry table").length > 0) {
    $(".article__entry table").each(function () {
      $(this).addClass("table").wrap("<div class='table-responsive'></div>");
    });
  }

  // pokaż btn back to homepage kiedy szablony w artykułach sa nieaktywne
  if ($(".article__entry p").length > 0) {
    $(".backToHomepage").addClass("visible");
    // $(".backToHomepage").addClass("show");
    $(".wboBelkaCover").addClass("visible");
  }

  // Share post - pokaz
  $("[data-sharePost]").on("click", function (e) {
    e.preventDefault();

    var $container = $(this).closest(".sharePostBox");

    $container.find("[data-overlay]").addClass("showOverlay");
    $container.find(".sharePost__content").addClass("showBox");
  });

  // Share post - ukryj
  $("[data-overlay]").on("click", function (e) {
    e.preventDefault();

    $("[data-overlay]").removeClass("showOverlay");
    $(".sharePost__content").removeClass("showBox");
  });

  $(document).on("keydown", function (e) {
    if (e.keyCode === 27 && $(".sharePost__content").is(":visible")) {
      $(".sharePost__content").removeClass("showBox");
      $("[data-overlay]").removeClass("showOverlay");
    }
  });

  // kopiowanie adresu url strony
  $("[data-copyLink]").on("click", function (e) {
    e.preventDefault();
    copyUrl();
  });

  function copyUrl() {
    if (!window.getSelection) {
      alert("Skopiuj adres URL z paska adresu.");
      return;
    }
    const dummy = document.createElement("p");
    dummy.textContent = window.location.href;
    document.body.appendChild(dummy);

    const range = document.createRange();
    range.setStartBefore(dummy);
    range.setEndAfter(dummy);

    const selection = window.getSelection();

    // Wyczyść, na wypadek, gdyby użytkownik wybrał już inny tekst
    selection.removeAllRanges();
    selection.addRange(range);

    document.execCommand("copy");
    document.body.removeChild(dummy);

    $("[data-change-copy-text]").text("Skopiowano!");
  }

  // kopiowanie adresu url strony + dodanie id do konkretnej sekcji do ktorej kopiujemy link
  $("[data-copyLink-withId]").on("click", function (e) {
    e.preventDefault();
    const sectionId = $(this).closest('[data-copy-id]').attr('id');

    copyUrlWithId(sectionId);
  });

  function copyUrlWithId(sectionId) {
    if (!window.getSelection) {
      alert("Skopiuj adres URL z paska adresu.");
      return;
    }

    // Skonstruuj URL z fragmentem (#) do danej sekcji
    const urlToCopy = `${window.location.origin}${window.location.pathname}#${sectionId}`;

    // Tworzenie tymczasowego elementu do kopiowania
    const dummy = document.createElement("p");
    dummy.textContent = urlToCopy;
    document.body.appendChild(dummy);

    // Tworzenie zakresu zaznaczenia dla skopiowania URL
    const range = document.createRange();
    range.selectNode(dummy);

    const selection = window.getSelection();
    selection.removeAllRanges();  // Wyczyszczenie poprzednich zaznaczeń
    selection.addRange(range);    // Dodanie nowego zakresu

    // Skopiowanie URL do schowka
    document.execCommand("copy");

    // Usunięcie tymczasowego elementu z DOM
    document.body.removeChild(dummy);

    // Aktualizacja tekstu na przycisku
    $(`[id=${sectionId}] [data-change-copy-text]`).text("Skopiowano!");
  }
});

// Share button - facebook
$('#facebook-share').on('click keypress', function(event) {
  // Sprawdzamy, czy użytkownik nacisnął klawisz Enter lub Spację
  if (event.type === 'click' || event.key === 'Enter' || event.key === ' ') {
    event.preventDefault();

    const currentUrl = encodeURIComponent(window.location.href);
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;
    window.open(shareUrl, '_blank');
  }
});